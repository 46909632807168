@import url(https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500&display=swap);

@font-face {
  font-family: "Shapiro";
  src: local("Shapiro"), url(/static/media/shapiro.73863665.woff) format("truetype");
}

@font-face {
  font-family: "Migra";
  src: local("Migra"), url(/static/media/migra.ed0dcb08.otf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Migra";
  src: local("Migra"), url(/static/media/migra-Regular.134cf68a.woff) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Migra";
  src: local("Migra"), url(/static/media/migra-Extralight.62a03ac0.woff) format("truetype");
  font-weight: 100;
}

/* @import url("https://fonts.googleapis.com/css2?family=Avenir:wght@300;400;600;700;800&display=swap"); */
