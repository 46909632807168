
@font-face {
  font-family: "Shapiro";
  src: local("Shapiro"), url(./assets/fonts/shapiro.woff) format("truetype");
}

@font-face {
  font-family: "Migra";
  src: local("Migra"), url(./assets/fonts/migra.otf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Migra";
  src: local("Migra"), url(./assets/fonts/migra-Regular.woff) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Migra";
  src: local("Migra"), url(./assets/fonts/migra-Extralight.woff) format("truetype");
  font-weight: 100;
}

/* @import url("https://fonts.googleapis.com/css2?family=Avenir:wght@300;400;600;700;800&display=swap"); */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500&display=swap');